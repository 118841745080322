@import "../../styles/variables.scss";

.map {
    position: relative;
    width: 100%;
    color: #fff;
    z-index: 5;
    background-color: black;
    mix-blend-mode: screen;
    padding: 100px 0;
    .wrap {
        position: relative;
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        .col_stats {
            display: flex;
            width: 30%;
            margin-left: 10%;
            flex-direction: column;
            .stat {
                display: flex;
                flex-direction: row;
                margin-bottom: 60px;
                h2 {
                    font-family: $maax-bold;
                    color: $yellow;
                    font-size: 9rem;
                    margin: 0;
                    line-height: .7;
                }
                .label {
                    align-self: flex-end;
                    margin-left: 10px;
                    color: $darkgray;
                    p {
                        margin: 5px;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .col_video {
            display: block;
            width: 60%;
            video {
                position: relative;
                width: 100%;
                height: auto;
                mix-blend-mode: screen;
            }
        }
    }
}

@media all and (max-width: 1023px) {
    .map {
        padding: 50px 0;
        .wrap {
            flex-direction: column;

            .col_stats {
                order: 2;
                width: 100%;
                margin-bottom: 25px;
                &:last-child {
                    margin-bottom: 0;
                }
                .stat {
                    h2 {
                        font-size: 5rem;
                    }
                }
            }
            .col_video {
                order: 1;
                width: 100%;
                margin-bottom: 40px;
            }
        }
    }
}